import React from 'react';
import cx from 'classnames'
import PropTypes from 'prop-types'
import {
  Header
} from 'semantic-ui-react';

const useKeyOnly = (val, key) => val && key

const CustomHeader = ({ mobile, subheader, header, inverted}) => {

  // const {
  //   attached,
  //   block,
  //   children,
  //   className,
  //   color,
  //   content,
  //   disabled,
  //   dividing,
  //   floated,
  //   icon,
  //   image,
  //   inverted,
  //   size,
  //   sub,
  //   subheader,
  //   textAlign,
  // } = props

  const classes = cx(
    'ui',
    useKeyOnly(inverted, 'inverted'),
    'custom-header',
    'floated'
  )

  return (
    <header className={classes} 
            style={{
              marginTop: mobile ? '1.5em' : '9em',
              maxWidth: mobile? 'auto' : '430px'
            }}
    >
      <p className='context'>{subheader}</p>
      <Header
        as='h1'
        content={header}
        inverted={inverted}
        floated='left'
        style={{
          fontSize: mobile ? '2em' : '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          textAlign: 'left',
          lineHeight: '0.9em'
        }}
      />
    </header>
  )
}

CustomHeader.propTypes = {
   /** Inverts the color of the header for dark backgrounds. */
  inverted: PropTypes.bool
}
export default CustomHeader;