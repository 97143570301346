import React from 'react';
import { Container, Header, Grid } from 'semantic-ui-react'

import ContactForm from './ContactForm';
import TestimonialCarousel from './TestimonialCarousel';
import Accreditation from './Accreditation';
import SemanticCarousel from './SemanticCarousel';

// https://github.com/rcaferati/ras-fullpage-strategies

// <Grid container className="ui two column stackable grid">

// TODO Missing validation
// TODO Missing better thank you

const ContactSection = ({mobile}) =>{
  return (
    <div id='contact' className='contact-section'>
      <Grid container className="ui three column stackable grid">
        <Grid.Column width={4} className='testimonials'>
          <SemanticCarousel />
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column id='get-in-touch' width={11} className='contact-container'>
          <ContactForm />
          <Accreditation />
        </Grid.Column>
      </Grid>
    </div>    
  )
}

export default ContactSection;