import React, {useState, useEffect} from 'react';
import axios from 'axios';

import FeefoImage from '/assets/images/Feefo_logo.png';
import SagepayImage from '/assets/images/sagepay-logo.png';
import EngineYardImage from '/assets/images/Engine_Yard_logo.png'; 
import HeartOfTheCity from '/assets/images/HotC_logo.png';
import GoogleCertified from '/assets/images/Google-partner-badge.png';

const Accreditation = () => {

  const [accreditations, setAccreditations] = useState([]);

  React.useEffect(() => {
    axios.get('/api/v1/accreditations.json')
     .then(resp => resp.data )
     .then(data => setAccreditations(data.results) )
  }, []);

  const renderAccreditations = () => {    
    return accreditations.map( (accreditation) => {
      if(accreditation.url){
        return (<div key={accreditation.id} className="column">
          <div className="ui segment">          
            <a href={accreditation.url} title="" target="_blank">
              <img src={accreditation.image.url} alt={accreditation.alt} />
            </a>      
          </div>
        </div>)
      }else{
        return (<div key={accreditation.id} className="column">
          <div className="ui segment">          
            <img src={accreditation.image.url} alt={accreditation.alt} />
          </div>
        </div>)
      }     
    });
    return <div>Aksldksald</div>;
  }

  const numberAsText = (number) => {
    switch(number){
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4: 
        return 'four';
      case 5:
        return 'five';
      case 6:
        return 'six';
      case 7:
        return 'seven';
      case 8:
        return 'eight';
      default:
        return 'five'                    
    }
  }

  return (

    <div className={`ui ${numberAsText(accreditations.length)} column grid stackable accreditation`} >
      {renderAccreditations()}
    </div>

  )
}

export default Accreditation;