import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  //Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import DesktopMenu from 'components/DesktopMenu';

import CustomHeader from 'components/CustomHeader';
import WhatWeDo from 'components/WhatWeDo';
import ContactSection from 'components/ContactSection';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MobileMenu from 'components/MobileMenu';

//https://medium.com/@yukiyao/use-a-different-font-in-semantic-ui-ffba49dd473b
//https://blog.logrocket.com/building-a-custom-dropdown-menu-component-for-react-e94f02ced4a1/

import { createMedia } from '@artsy/fresnel';
import ServicesWeProvide from 'components/ServicesWeProvide';
import LaptopImage from '/assets/images/the-laptop-pens-phone-note-with-blank-screen-on-P2PX4T3.png';
import LaptopImageMobile from '/assets/images/homepage-background-mobile.png';
//import Logo from '/assets/images/Logo.png';
import HorizontalLogoWhite from '/assets/images/moneyspyder-horizontal-logo-white.svg';

import { createBrowserRouter, RouterProvider, Link, BrowserRouter, Route, Routes } from 'react-router-dom';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  return window.innerWidth
}


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const HomepageHeading = ({ mobile }) => (

  <Container>
    <CustomHeader 
      header="Digital solutions, marketing transformation, software integration" 
      subheader="Digital Services Specialist" 
      mobile={mobile}
      inverted
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
}

ServicesWeProvide.propTypes = {
  mobile: PropTypes.bool  
}

// background-image: url('/assets/Page1-c3f9ff19ecceb1d8e19ab49215bb40386eb0d31314de29a853afee59ee1f392d.png') !important;
// background-size: cover !important;

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const DesktopContainer = ({children}) => {
  const [fixed, setFixed] = useState(false);

  const hideFixedMenu = () => {
    setFixed(false)
  }

  const showFixedMenu = () => {
    setFixed(true)
  }

  return (
    <Media greaterThan='mobile'>      
        <Visibility
          once={false}
          onBottomPassed={showFixedMenu}
          onBottomPassedReverse={hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ 
              minHeight: 700, 
              padding: '1em 0em', 
              backgroundColor: '#75b5d1', 
              backgroundSize: 'cover', 
              transition: 'background-image 0.2s ease-in-out',
              backgroundImage: `url(${LaptopImage})` 
            }}
            vertical
          >
            <DesktopMenu fixed={fixed} inverted={!fixed} anchors={['what-we-do']} />
            <HomepageHeading />
          </Segment>
        </Visibility>
        <WhatWeDo />
        <ServicesWeProvide />
        {children}
    </Media>
  )
}

//class MobileContainer extends Component {
const MobileContainer = ({children}) => {

  const [sidebarOpened, setSidebarOpened] = useState(false);

  return (
    <Media at='mobile'>
      <Sidebar.Pushable
        //as={Sidebar.Pushable}
        //getWidth={getWidth}
        //maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <MobileMenu sidebarOpened={sidebarOpened} handleSidebarHide={ () => { setSidebarOpened(false) } } />
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            //style={{ minHeight: 350, padding: '1em 0em' }}
            style={{ minHeight: 350, padding: '1em 0em', backgroundSize: 'cover', backgroundImage: `url(${LaptopImageMobile})` }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={ () => { setSidebarOpened(true) } }>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Image fluid style={{width: '50%'}} src={HorizontalLogoWhite} />
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          <WhatWeDo />
          <ServicesWeProvide mobile />
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  )
}

// DesktopContainer.propTypes = {
//   children: PropTypes.node,
// }

// https://frontarm.com/james-k-nelson/passing-data-props-children/
const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    <ContactSection />
  </ResponsiveContainer>
)

export default HomepageLayout