import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  //Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {
  Link
} from "react-router-dom";

import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';
import CustomHeader from 'components/CustomHeader';
import WhatWeDo from 'components/WhatWeDo';
import ContactSection from 'components/ContactSection';
import AnchorLink from 'react-anchor-link-smooth-scroll';

//https://medium.com/@yukiyao/use-a-different-font-in-semantic-ui-ffba49dd473b
//https://blog.logrocket.com/building-a-custom-dropdown-menu-component-for-react-e94f02ced4a1/

import { createMedia } from '@artsy/fresnel';
import ServicesWeProvide from 'components/ServicesWeProvide';
import ClientsContactSheet from 'components/ClientsContactSheet';
import LaptopImage from '/assets/images/the-laptop-pens-phone-note-with-blank-screen-on-P2PX4T3.png';
import LaptopImageMobile from '/assets/images/homepage-background-mobile.png';
//import Logo from '/assets/images/Logo.png';
import HorizontalLogoWhite from '/assets/images/moneyspyder-horizontal-logo-white.svg';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  return window.innerWidth
}

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const HomepageHeading = ({ mobile }) => (

  <Container>
    <CustomHeader 
      header="Digital solutions, marketing transformation, software integration" 
      subheader="Digital Services Specialist" 
      mobile={mobile}
      inverted
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
}

ServicesWeProvide.propTypes = {
  mobile: PropTypes.bool  
}

const CaseStudyTile = ({logo,introduction,url}) => {

  const handleChange = () =>{
    // <Button basic color='grey' onClick={handleChange}>
  }

  return (
    <div className='case-study-tile'>
      <h4>Casestudy</h4>
      <Image src={logo} fluid />
      <div className='summary'>
        {introduction}
      </div>
      <div className='button-container'>        
        <Button basic color='grey' as='a' href={url}>
          { 'Read More' }
        </Button>        
      </div>
    </div>
  )
}

const Page = ({slug}) => {

  const [page, setPage] = React.useState({title: '', first_column: '', second_column: ''});

  React.useEffect(() => {
    axios.get(`/api/v1/static_pages/${slug}.json`, {responseType: 'json'})
     .then(resp => resp.data )
     .then(data => setPage(data))
  }, [])

  return (
    <div className='page-content'>
      <Grid columns='two' divided>
        <Grid.Row>
          <Grid.Column>
            <ReactMarkdown
              children={page.first_column}
              escapeHtml={false}
            />
          </Grid.Column>
          <Grid.Column>
            <ReactMarkdown
              children={page.second_column}
              escapeHtml={false}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>              
    </div>
  )
}

const PageHeader = ({slug}) => {

  const [page, setPage] = React.useState({title: '', content: ''});

  React.useEffect(() => {
    axios.get(`/api/v1/static_pages/${slug}.json`, {responseType: 'json'})
     .then(resp => resp.data )
     .then(data => setPage(data))
  }, [])

  return (
    <div className='page-header'>
      <h1>{page.title}</h1>
      <h2>{page.content}</h2>             
    </div>
  )
}

// background-image: url('/assets/Page1-c3f9ff19ecceb1d8e19ab49215bb40386eb0d31314de29a853afee59ee1f392d.png') !important;
// background-size: cover !important;

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan='mobile'>
        <Container className='client-index-container'>      
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              inverted
              textAlign='center'
              style={{ 
                minHeight: 400, 
                padding: '1em 0em'
              }}
              vertical
            >
              <DesktopMenu fixed={fixed} inverted={false} />  
              <Container className='body-content'>          
              
                <Grid container className="ui two column stackable grid">
                  <Grid.Column width={5}>
                    <ClientsContactSheet />
                  </Grid.Column>
                  <Grid.Column width={3} />
                  <Grid.Column width={8}>
                    <PageHeader slug='clients' />
                  </Grid.Column>
                </Grid>              
              </Container>
            </Segment>
          </Visibility>

          <Container className='body-content'>          
            <Grid container className="ui two column stackable grid">
              <Grid.Column width={7} />
              <Grid.Column width={9}>
                <Page slug='clients' />
              </Grid.Column>
            </Grid>
          </Container>          
        </Container>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media at='mobile'>
      <div
        as={Sidebar.Pushable}
      >
        <MobileMenu sidebarOpened={sidebarOpened} handleSidebarHide={this.handleSidebarHide} />
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            //style={{ minHeight: 350, padding: '1em 0em' }}
            style={{ minHeight: 350, padding: '1em 0em', backgroundSize: 'cover', backgroundImage: `url(${LaptopImageMobile})` }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Image fluid style={{width: '50%'}} src={HorizontalLogoWhite} />
              </Menu>

              <Grid container className="ui two column stackable grid">
                <Grid.Column width={5}>
                  <ClientsContactSheet />
                </Grid.Column>
                <Grid.Column width={3} />
                <Grid.Column width={8}>
                  <Page slug='clients' />
                </Grid.Column>
              </Grid>              

            </Container>            
          </Segment>
        </Sidebar.Pusher>
      </div>
      {children}
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

// https://frontarm.com/james-k-nelson/passing-data-props-children/
const ResponsiveContainer = ({ serviceId, children }) => {

  const [content, setContent] = React.useState({name: '', summary: '', column_one: '', column_two: '', clients: []});

  // React.useEffect(() => {
  //   axios.get(`/api/v1/services/${serviceId}.json`, {responseType: 'json'})
  //    .then(resp => resp.data )
  //    .then(data => setContent(data))
  // }, [])

  return (
    <MediaContextProvider>
      <DesktopContainer content={content}>{children}</DesktopContainer>
      <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
  )
}

// ResponsiveContainer.propTypes = {
//   children: PropTypes.node,
// }

const ClientIndexPage = (props) => {
  return (
    <ResponsiveContainer>
      <ContactSection />
    </ResponsiveContainer>
  )
}

export default ClientIndexPage;