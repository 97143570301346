import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import PropTypes from 'prop-types';
import { createBrowserRouter, RouterProvider, Link, BrowserRouter, Route, Routes } from 'react-router-dom';

//https://github.com/fomantic/Fomantic-UI
//import 'fomantic-ui-css/semantic.min.css';

import HomepageLayout from 'components/HomepageLayout';
import ClientPage from 'components/ClientPage';
import ClientIndexPage from 'components/ClientIndexPage';
import ServicePage from 'components/ServicePage';
import Blog from 'components/Blog';
//import ScrollToTop from 'components/ScrollToTop';
import TempPage from 'components/TempPage';

//import history from "browser_history";

// const App = () => {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/">
//           <Route index element={TempPage} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   )
// }

// https://raw.githubusercontent.com/Semantic-Org/Semantic-UI-React/master/docs/src/layouts/HomepageLayout.js


      // <BrowserRouter>
      //   <Routes>
      //     <Route path="/">
      //       <Route index element={<WelcomePage {...data} />} />
      //       <Route path="specification/products" element={<ProductsLocationBar />} />
      //       <Route path="specification/products/:product_id" element={<ProductPage />} />

      //       <Route path='testimonial/products' exact element={<TestimonialSearch />} />
      //       <Route path='testimonial/products/:product_id' element={<Testimonial />} />
            
      //       {/* Using path="*"" means "match anything", so this route
      //             acts like a catch-all for URLs that we don't have explicit
      //             routes for. */}
      //       {/*<Route path="*" element={<NoMatch />} />*/}
      //     </Route>
      //   </Routes>
      // </BrowserRouter>

      // <Routes>
      //   <Route path="/" element={<Layout />}>
      //     <Route index element={<Home />} />
      //     <Route path="about" element={<About />} />
      //     <Route path="dashboard" element={<Dashboard />} />

      //     {/* Using path="*"" means "match anything", so this route
      //           acts like a catch-all for URLs that we don't have explicit
      //           routes for. */}
      //     <Route path="*" element={<NoMatch />} />
      //   </Route>
      // </Routes>


// https://github.com/remix-run/react-router/blob/dev/examples/basic/src/App.tsx


// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: (
//       <div>
//         <h1>Hello World</h1>
//         <Link to="about">About Us</Link>
//       </div>
//     ),
//   },
//   {
//     path: "about",
//     element: <div>About</div>,
//   },
// ]);



// const App = () => {
//   return (
//     <div>
//       <Router history={history}>
//         <div>
//           <ScrollToTop />
//           <Switch>
//             <Route path='/' exact component={HomepageLayout} />  
//             <Route path='/clients' exact component={ClientIndexPage} />
//             <Route path='/clients/:id' exact component={ClientPage} />
//             <Route path='/services/:id' exact component={ServicePage} />
//             <Route path='/blog_posts' exact component={Blog} />         
//           </Switch>
//         </div>
//       </Router>
//     </div>
//   )
// }  

// <Route path='/services/:id' exact component={ServicePage} />

const router = createBrowserRouter([
  {
    path: "/",
    element: (<HomepageLayout />),
  },
  {
    path: "about",
    element: <div>About</div>,
  },
  {
    path: "clients",
    element: (<ClientIndexPage />),
  },
  {
    path: "clients/:id",
    element: (<ClientPage />),
  },  
  {
    path: "blog_posts",
    element: (<Blog />),
  },  
  {
    path: "services/:id",
    element: (<ServicePage />),
  },  
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

// const App = () => {
//   return (
//     <Routes>
//       <Route path="/">
//         <Route index element={<HomepageLayout />} />
//       </Route>
//     </Routes>
//   )
// }

document.addEventListener('DOMContentLoaded', () => {
  //const element = document.querySelector('#root');
  //const root = ReactDOMClient.createRoot(node);
  const node = document.getElementById('root')
  const root = ReactDOMClient.createRoot(node);
  root.render(<App />);
  // ReactDOM.render(
  //   <App />,
  //   document.querySelector('#root')
  // )
})