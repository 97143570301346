import React from 'react';
//import { makeStyles } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


// https://material-ui.com/styles/basics/
const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    '::placeholder': {
      backgroundColor: 'red',
      color: 'blue'
    },
    'label': {
      backgroundColor: 'green'
    },
    //backgroundColor: 'red',
    '& > *': {
      //margin: theme.spacing(1),
      margin: '10px 0',
      width: '100%'
      //width: '25ch',
    },
  },
}));


    // '&:focus': {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },

    // '&:focus': {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },    

const ContactFields = (props) =>{
  const classes = useStyles();

  // const handleSubmit = (e) => {
  //   console.log(e);
  // }

  const handleOnClick = (e) => {
    props.onSubmit();
  }

  // TODO Need to include the error attribute 
  // https://material-ui.com/components/text-fields/
  return (
    <form className={classes.root} autoComplete="off">

      <Stack spacing={2}>
        <TextField  id="name" 
                    label="Name" 
                    onChange={props.onNameChange} 
                    value={props.name} 
                    variant="standard"
                    helperText={props.helper_text.name}
        />
        <TextField 
                    id="email" 
                    label="Email" 
                    onChange={props.onEmailChange} 
                    value={props.email}
                    variant="standard"
                    helperText={props.helper_text.email}
        />
        <TextField
          id="message"
          label="Type your message here"
          multiline
          rows={5}
          variant="standard"
          onChange={props.onMessageChange}
          value={props.message}
        />      
        <Button disabled={props.loading} variant="contained" onClick={handleOnClick} color="primary" disableElevation>
          Send
        </Button>    
      </Stack>  
    </form>
  );
}

// <TextField id="filled-basic" label="Filled" variant="filled" />
// <TextField id="outlined-basic" label="Outlined" variant="outlined" />

export default ContactFields