import React from 'react';
import axios from 'axios';
import {
  Header,
  Segment,
  Grid,
  Container
} from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import {Link, useParams} from "react-router-dom";

import ResponsiveContainer from 'components/ResponsiveContainer';
import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';

import CustomHeader from 'components/CustomHeader';
import LaptopImage from '/assets/images/business-management-and-finance.png';
import GenericImage from '/assets/images/client-default-background.png';
import ContactSection from 'components/ContactSection';
//import ClientBackgroundImage from '/assets/images/screen_linley.png';

const mobile = false;

const ClientHeader = ({fixed, name, summary, content, subject, strapline, backgroundStyle}) => {
  
  //const summary = 'Linley are a leading interiors and furniture company based in Chelsea. After a previous failed experiment running PPC they were cautious about re-trying Adwords. We reviewed their historic campaigns and identified why they hadn’t been performing. We also conducted research to demonstrate to Linley that there were a large number of high volume niche keywords that would perform better than the broad keywords they had been bidding on.';

  return (
    <Segment
      inverted
      textAlign='center'
      style={backgroundStyle}
      vertical
    >
      <DesktopMenu fixed={fixed} inverted={!fixed} />
      <Container>
        <header className='ui inverted custom-header case-study-header floated'
                style={{
                  marginTop: mobile ? '1.5em' : '4em',
                  maxWidth: mobile? 'auto' : '430px'
                }}
        >
          <p className='context'>Case Study: {subject}</p>
          <Header
            as='h1'
            content={name}
            inverted={true}
            floated='left'
            style={{
              fontSize: mobile ? '2em' : '4em',
              fontWeight: 'normal',
              marginBottom: '0.9em',
              textAlign: 'left',
              lineHeight: '0.9em'
            }}
          />
          <Header 
            as='h2'
            inverted={true}
            content={summary}
            style={{
              textAlign: 'left',
              marginBottom: '2.4em'
            }}
          />
          <Grid columns={2}>
            <Grid.Row>            
              <Grid.Column>
                <div className='ui strapline'>{strapline}</div>
              </Grid.Column>
              <Grid.Column>
                <div className='client-content'>
                  <ReactMarkdown
                    children={content}
                    escapeHtml={false}
                  />
                </div>
              </Grid.Column>            
            </Grid.Row>
          </Grid>
        </header>
      </Container>
    </Segment>
  )
}

const ClientPage = ({props}) => {

  let { id } = useParams();
  const [content, setContent] = React.useState({name: '', summary: '', content: '', subject: '', strapline: '', backgroundImage: null});

  const renderHeader = (fixed) => {

    var style = { 
      minHeight: 800, 
      padding: '1em 0em', 
      backgroundColor: '#9EC9E1', 
      backgroundSize: 'cover', 
      transition: 'background-image 0.2s ease-in-out'      
    }
    if(content.backgroundImage == null){
      style = {...style, ['backgroundImage'] : `url(${GenericImage})`}
    }else{
      style = {...style, ['backgroundImage'] : `url(${content.backgroundImage})`}
      //style = {...style, ['backgroundPosition'] : '60% 54%'}
    }

    return (
      <ClientHeader fixed={fixed} 
                    name={content.name} 
                    summary={content.introduction}
                    subject={content.subject} 
                    //backgroundImage={content.backgroundImage}
                    content={content.content}
                    strapline={content.strapline}
                    backgroundStyle={style}
      />
    );
  }

  React.useEffect(() => {
    axios.get('/clients/'+id+'.json', {responseType: 'json'})
     .then(resp => resp.data )
     .then(data => setContent(data))
  }, []);

  return (
    <ResponsiveContainer desktopHeader={ (fixed) => renderHeader(fixed) }>
      <div className='client-page'></div>
      <ContactSection />
    </ResponsiveContainer>
  );
}

export default ClientPage;