import React, {useState} from 'react';
import axios from 'axios';
import { Grid, Image } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const ClientsContactSheet = () => {

  const [clients, setClients] = useState([]);

  React.useEffect(() => {
    axios.get(`/api/v1/clients.json`, {responseType: 'json'})
     .then(resp => resp.data )
     .then(data => setClients(data.clients))
  }, [])

  return (
    <div className='client-contact-sheet'>
      <h2>Clients we’ve worked with</h2>
      <h3>Select below to read about our strategies</h3>
      <Grid container className="ui three stackable grid padded">
        {clients.map( client => {
          return (
            <Grid.Column key={client.id} computer={5} mobile={4}>
              <Link to={client.url}>
                <Image src={client.logo} fluid />
              </Link>
            </Grid.Column>
          )
        })}
      </Grid>
    </div>
  )
}

export default ClientsContactSheet;