import React, {useState, useEffect} from 'react';
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Dropdown,
  Visibility,
} from 'semantic-ui-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import axios from 'axios';

const MobileMenu = ({sidebarOpened, handleSidebarHide,  anchors=[]}) => {

  const [services, setServices] = useState([]);

  // TODO Loading services in desktop and mobile menu isn't great
  useEffect(() => {
    axios.get('/api/v1/services.json')
     .then(resp => resp.data )
     .then(data => setServices(data.services) ) 
  }, []);  

  const aboutUsLink = () => {
    if(anchors.indexOf('what-we-do') >= 0){
      return <HashLink smooth to='#what-we-do'>About us</HashLink>;
    }else{
      return <HashLink to='/#what-we-do'>About us</HashLink>;
    }
  }

  const dropdownServiceMenu = () => {
    if(services.length == 0) return;
    return (
      <Dropdown.Menu>
        {services.map( service => {
          return (
            <Menu.Item as='div' key={service.id}>
              <Link to={service.url} className='item' role='option'>{service.name}</Link>
            </Menu.Item>
          )
        })}
      </Dropdown.Menu>      
    )
  }  

  return (
    <Sidebar
      as={Menu}
      animation='push'
      inverted
      onHide={handleSidebarHide}
      vertical
      visible={sidebarOpened}
    >
      <Menu.Item as='div' onClick={handleSidebarHide}>
        <Link to='/' >
          Home
        </Link>
      </Menu.Item>

      <Menu.Item as='div' onClick={handleSidebarHide}>
        {aboutUsLink()}
      </Menu.Item>

      <Menu.Item>
        <Link to='/clients'>Clients</Link>
      </Menu.Item>

      <Dropdown text='Services' className='link item'>
        {dropdownServiceMenu()}
      </Dropdown>      

      <Menu.Item as='div'>
        <Link to='/blog_posts'>Blog</Link>
      </Menu.Item>

      <Menu.Item as='div' onClick={handleSidebarHide}>
        <HashLink smooth to='#contact'>Contact</HashLink>
      </Menu.Item>
      
    </Sidebar>    
  )
}

export default MobileMenu;
