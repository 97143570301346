import React from 'react';
import { Image, Header, Grid, Item, Container } from 'semantic-ui-react';
import Collapse from '@mui/material';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import ServiceItem from 'components/ServiceItem';
import servicesBackground from '/assets/images/services-we-provide.png';

// https://codepen.io/taniarascia/pen/VpGXJQ
// https://www.taniarascia.com/background-position-fixed-and-cover-with-css/


// TODO It would be nice to have transparent background images
// TODO Include the @2x sized images
import MagnifyImage from '/assets/images/magnify.png';
import GlassOfWaterImage from '/assets/images/glass-of-water.png';
import TabletImage from '/assets/images/tablet.png';
import BlockGraphImage from '/assets/images/block-graph.png';

import './ServicesWeProvide.css';

const imageTable = {
  'software-integration': TabletImage,
  'transformation-and-coaching': GlassOfWaterImage,
  'conversion-rate-optimisation': BlockGraphImage,
  'digital-marketing': MagnifyImage
}

const ItemExampleProps = (services) => {
  if(services.length === 0){
    return;
  }
  return services.map( (item) => {
    return (
        <ServiceItem 
          key={item.id}
          id={item.id}
          item={item} 
          slug={item.slug}
          header={item.name}
          image={imageTable[item.slug]}
          summary={item.summary}
          description={item.content}
        />
      )
  });
}

// https://github.com/Semantic-Org/Semantic-UI/issues/2968
// https://fomantic-ui.com/usage/layout.html

// <div className="eight wide column" style={{padding: 0}}>
//   <Header>Digital Marketing</Header>
// </div>

// TODO Maybe change mobile into a Context
const ServicesWeProvide = ({mobile}) =>{

  const [services, setServices] = React.useState([]);

  React.useEffect(() => {
    axios.get('/api/v1/services.json')
     .then(resp => resp.data )
     .then(data => setServices(data.services) )
  }, [])

  return (
    <div id='services-we-provide' className='services-we-provide'>
      <Container>
        <Grid stackable columns={2}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header
                  as='h1'
                  content="Services we provide"
                  inverted
                  floated='left'
                  style={{
                    fontSize: mobile ? '2em' : '4em',
                    fontWeight: 'normal',
                    marginBottom: 0,
                    textAlign: 'left',
                    lineHeight: '1.2em',
                    marginTop: mobile ? '1em' : '9em',
                    maxWidth: mobile? 'auto' : '430px'
                  }}
                />                    
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row>
                  <div className='services-bullets'>
                    <div className='ui items'>
                      {ItemExampleProps(services)}
                    </div>
                  </div>
                </Grid.Row>
              </Grid>
            </Grid.Column>          
          </Grid.Row>
        </Grid>    
      </Container>
    </div>
  );
}

export default ServicesWeProvide;
