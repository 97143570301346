import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import {
  // Button,
  Container,
  // Divider,
  // Grid,
  // Header,
  Icon,
  Image,
  // List,
  Menu,
  //Responsive,
  Segment,
  Sidebar,
  Visibility
} from 'semantic-ui-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { createMedia } from '@artsy/fresnel';

import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';

import LaptopImage from '/assets/images/the-laptop-pens-phone-note-with-blank-screen-on-P2PX4T3.png';
import LaptopImageMobile from '/assets/images/homepage-background-mobile.png';
import HorizontalLogoWhite from '/assets/images/moneyspyder-horizontal-logo-white.svg';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  return window.innerWidth
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  // TODO Would rather the images faded rather than pop
  render() {

    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan='mobile'>
        <div>
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            {this.props.desktopHeader(fixed)}
          </Visibility>
          {children}
        </div>
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
}

//class MobileContainer extends Component {
const MobileContainer = ({children}) => {
  //state = {}

  //handleSidebarHide = () => this.setState({ sidebarOpened: false })

  //handleToggle = () => this.setState({ sidebarOpened: true })
  const [sidebarOpened, setSidebarOpened] = useState(false);

  //render() {
    // const { children } = this.props
    // const { sidebarOpened } = this.state

  return (
    <Media at='mobile'>
      <div
        as={Sidebar.Pushable}
        //getWidth={getWidth}
        //maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <MobileMenu sidebarOpened={sidebarOpened} handleSidebarHide={ () => { setSidebarOpened(false) } } />

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            //style={{ minHeight: 350, padding: '1em 0em' }}
            style={{ minHeight: 350, padding: '1em 0em', backgroundSize: 'cover', backgroundImage: `url(${LaptopImageMobile})` }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={ () => { setSidebarOpened(true) } }>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Image fluid style={{width: '50%'}} src={HorizontalLogoWhite} />
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </div>
    </Media>
  )
}




const ResponsiveContainer = ({ children, desktopHeader }) => (
  <MediaContextProvider>
    <DesktopContainer desktopHeader={desktopHeader}>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

export default ResponsiveContainer;