import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  //Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {Link, useParams} from "react-router-dom";
import { createMedia } from '@artsy/fresnel';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';
import CustomHeader from 'components/CustomHeader';
import ContactSection from 'components/ContactSection';
import CaseStudyTile from 'components/CaseStudyTile';
import AnchorLink from 'react-anchor-link-smooth-scroll';

//https://medium.com/@yukiyao/use-a-different-font-in-semantic-ui-ffba49dd473b
//https://blog.logrocket.com/building-a-custom-dropdown-menu-component-for-react-e94f02ced4a1/

import ServicesWeProvide from 'components/ServicesWeProvide';
import LaptopImage from '/assets/images/the-laptop-pens-phone-note-with-blank-screen-on-P2PX4T3.png';
import LaptopImageMobile from '/assets/images/homepage-background-mobile.png';
//import Logo from '/assets/images/Logo.png';
import HorizontalLogoWhite from '/assets/images/moneyspyder-horizontal-logo-white.svg';
import HorizontalLogo from '/assets/images/moneyspyder-horizontal-logo.svg';


// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined';
  //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  return window.innerWidth
}

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

const HomepageHeading = ({ mobile }) => (

  <Container>
    <CustomHeader 
      header="Digital solutions, marketing transformation, software integration" 
      subheader="Digital Services Specialist" 
      mobile={mobile}
      inverted
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
}

ServicesWeProvide.propTypes = {
  mobile: PropTypes.bool  
}

// background-image: url('/assets/Page1-c3f9ff19ecceb1d8e19ab49215bb40386eb0d31314de29a853afee59ee1f392d.png') !important;
// background-size: cover !important;


const servicePageContent = ({name, summary, content, image}) => {
  return (
    <Container className='body-content service-page-content'>                        
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header
              as='h1'
              content={name}
              floated='left'
              style={{
                fontweight: 'normal',
                marginBottom: '0.9em',
                lineHeight: '0.9em'
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable reversed='mobile' >
        <Grid.Row>
          <Grid.Column width={9}>
            <Header
                    as='h2'
                    content={summary}
                    style={{
                      textAlign: 'left',
                      marginBottom: '0.9em'
                    }}
             />
            <div className="description">
              <ReactMarkdown
                children={content}
                escapeHtml={false}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <Image src={image.url} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

const serviceCaseStudies = ({clients}) => {
  return (
    <Grid stackable columns={3} className='case-studies'>
      <Grid.Row columns={3}>            
        {clients.map( (client) => {
          return (
            <Grid.Column key={client.id}>
              <CaseStudyTile {...client} />
            </Grid.Column>
          )  
        })}
      </Grid.Row>
    </Grid>
  )
}


/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan='mobile'>
        <div className='service-page-container'>
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              inverted
              textAlign='center'
              style={{ 
                minHeight: 700, 
                padding: '1em 0em', 
                backgroundColor: '#FFFFFF', 
              }}
              vertical
            >
              <DesktopMenu fixed={fixed} inverted={false} />
              {servicePageContent(this.props.content)}
            </Segment>
          </Visibility>
          {serviceCaseStudies(this.props.content)}
        </div>
        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media at='mobile'>
        <div
          as={Sidebar.Pushable}
          className='service-page-container'
        >
          <MobileMenu sidebarOpened={sidebarOpened} handleSidebarHide={this.handleSidebarHide} />
          
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ 
                minHeight: 300, 
                padding: '1em 0em', 
                backgroundColor: '#FFF'
              }}            
              vertical
            >
              <Container>
                <Menu pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Image fluid style={{width: '50%'}} src={HorizontalLogo} />
                </Menu>
              </Container>
              {servicePageContent(this.props.content)}
            </Segment>
            {serviceCaseStudies(this.props.content)}
            {children}
          </Sidebar.Pusher>
        </div>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

// https://frontarm.com/james-k-nelson/passing-data-props-children/
const ResponsiveContainer = ({ serviceId, children }) => {

  const [content, setContent] = React.useState({name: '', summary: '', content: '', image: {url: ''}, clients: []});

  React.useEffect(() => {
    axios.get(`/api/v1/services/${serviceId}.json`, {responseType: 'json'})
     .then(resp => resp.data )
     .then(data => setContent(data))
  }, [serviceId]);

  return (
    <MediaContextProvider>
      <DesktopContainer content={content}>{children}</DesktopContainer>
      <MobileContainer content={content}>{children}</MobileContainer>
    </MediaContextProvider>
  )
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const ServicePage = (props) => {

  let { id } = useParams();

  return (
    <ResponsiveContainer serviceId={id}>
      <ContactSection />
    </ResponsiveContainer>
  )
}

export default ServicePage;