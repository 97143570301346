import PropTypes from 'prop-types';
import React, {useState, useEffect, Component} from 'react';
import axios from 'axios';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  //Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import { createMedia } from '@artsy/fresnel';

import BlogPost from 'components/BlogPost';
import ContactSection from 'components/ContactSection';
import DesktopMenu from 'components/DesktopMenu';
import MobileMenu from 'components/MobileMenu';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LaptopImageMobile from '/assets/images/homepage-background-mobile.png';
//import Logo from '/assets/images/Logo.png';
import HorizontalLogoWhite from '/assets/images/moneyspyder-horizontal-logo-white.svg';
import DeviceContext from 'contexts/DeviceContext';


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  //return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  return  window.innerWidth;
}

const HomepageHeading = ({ mobile }) => (
  <Container>
    <CustomHeader 
      header="Digital solutions, marketing transformation, software integration" 
      subheader="Digital Services Specialist" 
      mobile={mobile}
      inverted
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
}

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    // https://reactgo.com/react-pass-props-children/
    const updateChildrenWithProps = React.Children.map(      
      this.props.children,      
      (child, i) => {        
        return React.cloneElement(child, {        
          //this properties are available as a props in child components          
          mobile: "false",          
          index: i        
        });      
      }    
    );    

    return (
      // <Responsive className='blog-page-container' getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Media greaterThan='mobile'>
        <DeviceContext.Provider value='desktop'>        
          <div className='blog-page-container'>
            <Container>
              <Visibility
                once={false}
                onBottomPassed={this.showFixedMenu}
                onBottomPassedReverse={this.hideFixedMenu}
              >
                <DesktopMenu fixed={fixed} inverted={!fixed} />
              </Visibility>                                  
              {updateChildrenWithProps}
            </Container>
          </div>
          <ContactSection />
        </DeviceContext.Provider>
      </Media>
    )
  }
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    // https://reactgo.com/react-pass-props-children/
    const updateChildrenWithProps = React.Children.map(      
      this.props.children,      
      (child, i) => {        
        return React.cloneElement(child, {        
          //this properties are available as a props in child components          
          mobile: "true",          
          index: i        
        });      
      }    
    );  

    return (
      <Media at='mobile'>
        <DeviceContext.Provider value='mobile'>
          <div
            as={Sidebar.Pushable}
            //getWidth={getWidth}
            //maxWidth={Responsive.onlyMobile.maxWidth}
          >
            <MobileMenu sidebarOpened={sidebarOpened} handleSidebarHide={this.handleSidebarHide} />

            <Sidebar.Pusher dimmed={sidebarOpened}>
              <Segment
                inverted
                textAlign='center'
                style={{ minHeight: 350, padding: '1em 0em', backgroundSize: 'cover', backgroundImage: `url(${LaptopImageMobile})` }}
                vertical
              >
                <Container>
                  <Menu inverted pointing secondary size='large'>
                    <Menu.Item onClick={this.handleToggle}>
                      <Icon name='sidebar' />
                    </Menu.Item>
                    <Image fluid style={{width: '50%'}} src={HorizontalLogoWhite} />
                  </Menu>
                </Container>
              </Segment>
              <Container>
                {updateChildrenWithProps}
              </Container>
            </Sidebar.Pusher>
          </div>
          <ContactSection />
        </DeviceContext.Provider>
      </Media>
    )
  }
}

const ResponsiveContainer = ({children}) => { 

  return (
    <MediaContextProvider>
      <DesktopContainer mobile="false">{children}</DesktopContainer>
      <MobileContainer mobile="true">{children}</MobileContainer>
    </MediaContextProvider>
  )
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const Blog = (props) => {

  const [posts, setPosts] = useState([]);

  React.useEffect(() => {
    axios.get('/api/v1/blog_posts.json')
     .then(resp => resp.data )
     .then(data => setPosts(data.results) )
  }, []);

  return (
    <ResponsiveContainer className='blog-posts'>
        {posts.map(post => {
          return (
            <BlogPost key={post.id} mobile={props.mobile} {...post} />
          )
        })}      
    </ResponsiveContainer>
  )
}

export default Blog;