import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import axios from 'axios';

const Testimonial = (props) => {
  return (
    <div className='item testimonial-container'>
      <p className='quote'>
        {props.quote}
      </p>
      <p className='large-quotes'>“”</p>
      <aside>{props.author}, {props.role}<br />{props.company}</aside>
    </div>
  )
}

const  SemanticCarousel = () => {

  const [testimonials, setTestimonials] = useState([]);

  React.useEffect(() => {
    const loadTestimonals = async () => {
      const {data} = await axios.get('/testimonials.json');
      setTestimonials(data.map( (testimonial) => {
        //console.log(testimonial)
        return <Testimonial {...testimonial} />
      }));
    }
    loadTestimonals();
  }, [])

  return (
    <AliceCarousel mouseTracking items={testimonials} disableButtonsControls={true} />
  )
}

export default SemanticCarousel;