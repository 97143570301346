import React from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import cx from 'classnames'
import CustomHeader from 'components/CustomHeader';

import WhatWeDoImage from '/assets/images/the-laptop-pens-phone-note-with-blank-screen-on-P2PX4T3 2.png';

const WhatWeDo = () => {

  const mobile = false;
  const classes = cx(
    'ui',
    //useKeyOnly(inverted, 'inverted'),
    'custom-header',
    'floated'
  )  

  return (  
    <Grid id='what-we-do' container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>

          <header className={classes} 
                  style={{
                    marginTop: mobile ? '1.5em' : '5em',
                    maxWidth: mobile? 'auto' : '430px'
                  }}
          >
            <p className='context'>What we do</p>
            <Header
              as='h1'
              content="An established player in digital marketing and ecommerce since 2006. We provide tailored marketing and software solutions to enable rapid change and sustained growth."
              floated='left'
              style={{
                fontSize: mobile ? '2em' : '2.4em',
                fontWeight: 'normal',
                marginBottom: 0,
                textAlign: 'left',
                lineHeight: '1.2em'
              }}
            />          
          </header>

        </Grid.Column>
        <Grid.Column className='collapse-left collapse-mobile' floated='right' width={8}>
          <Image fluid src={WhatWeDoImage} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
        
  )
}

// <Segment vertical></Segment>

export default WhatWeDo;