import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';

const CaseStudyTile = ({logo,introduction,url}) => {

  return (
    <div className='case-study-tile'>
      <h4>Casestudy</h4>
      <Image src={logo} fluid />
      <div className='summary'>
        <ReactMarkdown
          children={introduction}
          escapeHtml={false}
        />        
      </div>
      <div className='button-container'>
        <Link to={url} className='ui grey basic button'>Read More</Link>
      </div>
    </div>
  )
}

export default CaseStudyTile;