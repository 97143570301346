import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import { useForm, useField, splitFormProps } from "react-form";
import { Form, Icon, Header, TextArea, Button} from 'semantic-ui-react';
import axios from 'axios';

// https://github.com/tannerlinsley/react-form/blob/master/docs/examples.md
import ContactFields from 'components/ContactFields';

// <label>
// Name: <InputField field="name" validate={fakeCheckValidName} />
// </label>

const CompanyAddress = () => {

  const [settings, setSettings] = useState({address1: '', address2: '', city: '', phone: '', postcode: ''});

  useEffect(() => {
    axios.get('/api/v1/settings.json')
     .then(resp => resp.data )
     .then(data => setSettings(data) )
  }, []) 

  return (
    <p className='address' itemScope itemType="http://schema.org/Organization">
      <span itemProp="streetAddress">{settings.address1}</span>, <span itemProp="addressLocality">{settings.city}</span>, <span itemProp="postalCode">{settings.postcode}</span>
      <br />
      Tel: <span itemProp="telephone">{settings.phone}</span>
    </p>
  )
}

const FormHeader = () => {

  const [snippet, setSnippet] = useState({name: '', content: ''});

  useEffect(() => {
    axios.get('/api/v1/snippets/get-in-touch.json')
     .then(resp => resp.data )
     .then(data => setSnippet(data) )
  }, []);

  return (
    <>
      <Header as='h4'>{snippet.name}</Header>
      <Header as='h2' inverted>
        {snippet.content}
      </Header>    
    </>
  )
}

// TODO h4 should not be hardcoded

const ThankYouHeader = () => {
  const [snippet, setSnippet] = useState({name: '', content: ''});

  useEffect(() => {
    axios.get('/api/v1/snippets/get-in-touch-thank-you.json')
     .then(resp => resp.data )
     .then(data => setSnippet(data) )
  }, [])  

  return (
    <>
      <Header as='h4'>Get in touch</Header>
      <Header as='h2' inverted className='thank-you'>
        {snippet.content}
      </Header>   
    </>
  )  
}

class ContactForm extends React.Component{

  state = { 
    name: '', email: '', message: '', loading: false, submitted: false,
    helper_text: { name: '', email: ''}
  }

  onSubmit = () => {
    if(this.state.name == ''){
      this.setState({ helper_text: {...this.state.helper_text, ['name'] : 'Please enter a name'} });
      return;
    }
    if(this.state.email == ''){
      this.setState({ helper_text: {...this.state.helper_text, ['email'] : 'Please enter a valid email'} });
      return;
    }    

    this.sendMessage(this.state.name, this.state.email, this.state.message);
  }

  sendMessage = async (name, email,message) => {
    this.setState({loading: true});
    try {
      const response = await axios.post('/enquiries.json', {
        enquiry: {
          name: name,
          email_address: email,
          message: message
        }
      })
      this.setState({name: '', email: '', message: '', submitted: true})
    } catch(err) {
    } finally {
      this.setState({loading: false});
    }
    
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value, helper_text: {} });
  }

  onEmailChange = (e) => {
    this.setState({ email: e.target.value, helper_text: {} });
  }

  onMessageChange = (e) => {
    this.setState({ message: e.target.value, helper_text: {} });  
  }

  renderForm = () => {
    return (
      <>
        <FormHeader />
        <ContactFields  onSubmit={this.onSubmit} 
                        loading={this.state.loading}
                        onNameChange={this.onNameChange}
                        onEmailChange={this.onEmailChange}
                        onMessageChange={this.onMessageChange}
                        name={this.state.name}
                        email={this.state.email}
                        message={this.state.message}
                        helper_text={this.state.helper_text}
        />        
      </>
    )
  }

  renderThankYou = () => {
    return (
      <>
        <ThankYouHeader />
      </>
    )    
  }
  
  // TODO Need to have a better address section. 
  // - e.g. re-include photo of building posible include Google maps
  //
  // https://schema.org/address

  // https://stackoverflow.com/questions/21648347/true-custom-attributes-e-g-microdata-in-react
  // http://plnkr.co/edit/UjXSveVHdj8T3xnyhmKb?p=preview&preview

   render(){
    return (
      <div className='contact-form'>        
        <a className='item' href='https://twitter.com/Moneyspyder'>
          <Icon link name='twitter' />        
        </a>
        {this.state.submitted ? this.renderThankYou() : this.renderForm() }
        <CompanyAddress />
      </div>
    );
   }
}  

export default ContactForm;