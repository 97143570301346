import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import MagnifyImage from '/assets/images/magnify.png';
import GlassOfWaterImage from '/assets/images/glass-of-water.png';
import TabletImage from '/assets/images/tablet.png';
import BlockGraphImage from '/assets/images/block-graph.png';

const imageTable = {
  'software-integration': TabletImage,
  'transformation-and-coaching': GlassOfWaterImage,
  'conversion-rate-optimisation': BlockGraphImage,
  'digital-marketing': MagnifyImage
}

const ServiceItem = (props) => {

  // const ButtonLink = React.forwardRef((props, ref) => (
  //   <button ref={ref} {...props}>{props.children}</button>
  // ))
  const ButtonLink = React.forwardRef((props, ref) => (
    <a ref={ref} {...props}>{props.children}</a>
  ))

  const FancyLink = React.forwardRef((props, ref) => (
    <a ref={ref} {...props}>💅 {props.children}</a>
  ))

  return (
    <div className="item">
      <div className="image">
        <Image src={props.image} />
      </div>
      <div className="content">
        <div className="header">{props.header}</div>
        <div className="description">
          <ReactMarkdown
            escapeHtml={false}
            children={props.summary}
          />
        </div>
        <Link to={`/services/${props.slug}`} className='ui inverted button'>Read More</Link>
      </div>
    </div>
  )
}

export default ServiceItem;