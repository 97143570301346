import React, {useContext } from 'react';
import { Image, Header, Grid, Item, Button } from 'semantic-ui-react';
import Collapse from '@mui/material';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import DeviceContext from 'contexts/DeviceContext';

const BlogPost = ({published_on, title, summary, body, mobile}) => {

  const devise = useContext(DeviceContext);

  return (
    <div className='blog-post'>
      <header className='ui custom-header case-study-header floated'
              style={{
                marginTop: mobile ? '1.5em' : '4em',
                maxWidth: mobile ? 'auto' : '430px'
              }}
      >
        <p className='context'>Case Study: {published_on}</p>
        <Header
          as='h1'
          content={title}
          floated='left'
          inverted={devise == 'desktop' ? true : false}
          style={{
            fontSize: devise == 'mobile' ? '2em' : '4em',
            fontWeight: 'normal',
            marginBottom: '0.9em',
            textAlign: 'left',
            lineHeight: '0.9em'
          }}
        />
        <Header 
          as='h2'
          content={summary}
          inverted={devise == 'desktop' ? true : false}
          style={{
            textAlign: 'left',
            marginBottom: '2.4em'
          }}
        />
        <div className='blog-post-content' style={{
            marginBottom: '1rem'
          }}>
          <ReactMarkdown
            children={body}
            escapeHtml={false}
          />            
        </div>
      </header>
    </div>
  );
}

export default BlogPost;