import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Image,
  Dropdown,
  Menu
} from 'semantic-ui-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { Link } from "react-router-dom";

import HorizontalLogo from '/assets/images/moneyspyder-horizontal-logo.svg';
import LogoNew from '/assets/images/moneyspyder-logo2.svg';

// Dropdown Menu
// https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/StickyLayout.js

// TODO Change /clients.json into a separate component

//const ClientsContext = React.createContext('clients');

const DesktopMenu = ({fixed, inverted, anchors=[]}) => {

  const [clients, setClients] = React.useState([]);
  const [services, setServices] = React.useState([]);

  useEffect(() => {
    axios.get('/api/v1/clients.json')
     .then(resp => resp.data )
     .then(data => setClients(data.clients) )    
  }, []);

  useEffect(() => {
    axios.get('/api/v1/services.json')
     .then(resp => resp.data )
     .then(data => setServices(data.services) ) 
  }, []);     

  const dropdownServiceMenu = () => {
    if(services.length == 0) return;
    return (
      <Dropdown.Menu>
        {services.map( service => {
          return (
            <Link to={service.url} className='item' role='option' key={service.id}>{service.name}</Link>
          )
        })}
      </Dropdown.Menu>      
    )
  } 

  const aboutUsLink = () => {
    if(anchors.indexOf('what-we-do') >= 0){
      return <HashLink smooth to='#what-we-do'>About us</HashLink>;
    }else{
      return <HashLink to='/#what-we-do'>About us</HashLink>;
    }
  }

  return (
    <Menu
      fixed={fixed ? 'top' : null}
      inverted={inverted}
      secondary={!fixed}
      size='large'
    >
      <Container>
        <Menu.Item fitted style={{border: 'none'}}>
          <Link to='/' className='site-logo fitted item'>
            <Image src={`${fixed ? HorizontalLogo : LogoNew}`} />
          </Link>
        </Menu.Item>
        <div className='right menu'>
          <Menu.Item>
            {aboutUsLink()}
          </Menu.Item>
          <Menu.Item>
            <Link to='/clients'>Clients</Link>
          </Menu.Item>
          <Dropdown text='Services' className='link item'>
            {dropdownServiceMenu()}
          </Dropdown>
          <Menu.Item>
            <Link to='/blog_posts'>Blog</Link>
          </Menu.Item>          
          <Menu.Item>            
            <HashLink smooth to='#contact'>Contact</HashLink>
          </Menu.Item>
        </div>             
      </Container>
    </Menu>
  )
}
export default DesktopMenu;